import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Sherif Tarabishy', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: `I am an architectural engineer, software developer and researcher. Currently working as an Associate Partner and Design Systems Analyst at Foster + Partners' Applied R+D (ARD) group. My position there allows him to work on complex challenges on a daily basis, utilizing my expertise in geometry optimization, digital fabrication, virtual and augmented reality, and machine learning.
  I am also an instructor and lecturer at The Bartlett in the MSc. in Architectural Computation and the M.Arch. in Architectural Design courses. For over 12 years, I have been lecturing, training and consulting at different universities and firms, with a focus on digital transformation in the AEC industry.`, // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Hello, my name is',
  name: 'Sherif',
  subtitle: "I'm a computational designer",
  cta: 'Know more',
};

// ABOUT DATA
export const aboutData = {
  img: '',
  paragraphOne: 'I am an Architect, Computational Designer and Researcher.',
paragraphTwo: 'During my MSc in Architectural Computation at UCL’s The Bartlett, my thesis explored the possibility of utilizing Open Big Data and Machine Learning to automate design processes. My current position in the Applied Research + Development group at Foster+Partners, allows me to work on complex design challenges on a daily basis, utilizing my expertise in geometry optimization, robotics, digital fabrication, virtual and augmented reality and machine learning.',
  paragraphThree: 'For over 10 years, I lectured, trained and consulted different universities and firms in Egypt and the UK, discussing and advising on the integration and implementation of different technologies in the design to production workflow.',
  resume: '/resume.pdf', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  // {
  //   id: nanoid(),
  //   img: 'project.jpg',
  //   title: '',
  //   info: '',
  //   info2: '',
  //   url: '',
  //   repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  // }
];

// CONTACT DATA
export const contactData = {
  cta: 'Want to get in touch?',
  btn: '',
  email: 'sherif@starabishy.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'twitter',
      url: 'https://twitter.com/sheriftarabishy',
    },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/sheriftarabishy/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/tarabishy2020',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
